/* eslint-disable no-unused-vars */
/* eslint-disable no-template-curly-in-string */
import { Form, Button, Input, message, Checkbox, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
// import CallIcon from "@mui/icons-material/Call";
// import EmailIcon from "@mui/icons-material/Email";
import { Link } from "react-router-dom";
import img from "./images/Kurdish Translation Services.png";
import "./Home.css";
import emails from "./images/email.png";
import phone from "./images/telephone-call.png";
import { ArrowUpOutlined } from "@ant-design/icons";
import { animateScroll as scroll } from "react-scroll";
import Footer from "../Components/Footer";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const Book_now = () => {
  const { t } = useTranslation();
  const [full_name, setFullname] = useState("");
  const [phone_number, setphone_number] = useState(0);
  const [email, setemail] = useState("");
  const [country, setcountry] = useState("");
  const [subject, setsubject] = useState("");
  const [instructions, setinstructions] = useState("");
  const [files, setFiles] = useState([]);
  // ---------------------------------------------------
  const [form] = Form.useForm();
  // --------------------------------------------------------
  const Get_Api = async () => {
    let api = "https://api.kurdishtranslationservices4u.co.uk/api/bookings";
    let res = await fetch(api);
    res = await res.json();
    // console.log("res", res.data[0]);
  };
  useEffect(() => {
    Get_Api();
  }, []);
  //todo --------------------------------------------
  const scrollToTop = () => {
    scroll.scrollToTop(); // Scroll to the top of the page
  };
  //todo --------------------------------------------
  const Post_Api = async () => {
    if (!form.getFieldValue("Checkbox")) {
      message.warning("Please accept the privacy policy before submitting.");
      return;
    }

    const URI = "https://api.kurdishtranslationservices4u.co.uk/api/bookings";

    try {
      let formData = new FormData();

      formData.append("full_name", full_name);
      formData.append("phone_number", phone_number);
      formData.append("email", email);
      formData.append("country", country);
      formData.append("subject", subject);
      formData.append("instructions", instructions);

      files.forEach((file, index) => {
        formData.append(`files[${index}]`, file.originFileObj);
      });

      let res = await fetch(URI, {
        method: "POST",
        body: formData,
      });

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      res = await res.json();
      message.success("Form submitted successfully");
    } catch (error) {
      console.error("Error:", error);
      message.error("Failed to submit form. Please try again later.");
    }
  };
  // ------------------------------------------

  const handleReset = () => {
    form.resetFields();
  };

  //   --------------------------------
  const onFinish = (values) => {};
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const { TextArea } = Input;
  const validateMessages = {
    types: {
      email: "${label} is not a valid email!",
      required: "${label} is required!",
    },
  };
  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  };
  //   -------------------------------------

  const normFile = (e) => {
    // console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  return (
    <div>
      <div className="flex-container-ContactUs">
        <div className="flex-item-ContactUs-1">
          <p id="paragraph_form">{t("PageBookNowTitle")}</p>
          <Form
            form={form}
            {...layout}
            style={{
              maxWidth: 800,
            }}
            validateMessages={validateMessages}
            name="frm"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="FullName"
              label={t("FullName")}
              rules={[
                {
                  required: true,
                  message: "Please input your Full Name ",
                },
              ]}
            >
              <Input
                value={full_name}
                onChange={(text) => setFullname(text.target.value)}
                placeholder={t("One")}
              />
            </Form.Item>
            <Form.Item
              name="PhoneNumber"
              label={t("Phone")}
              rules={[
                {
                  required: true,
                  message: "Please input your Phone Number!",
                },
              ]}
            >
              <Input
                value={phone_number}
                onChange={(text) => setphone_number(text.target.value)}
                placeholder={t("Two")}
              />
            </Form.Item>
            <Form.Item
              name="Email"
              label={t("Email")}
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                  type: "email",
                },
              ]}
            >
              <Input
                value={email}
                onChange={(text) => setemail(text.target.value)}
                placeholder={t("Three")}
              />
            </Form.Item>
            <Form.Item
              name="YourCountry"
              label={t("YourCountry")}
              rules={[
                {
                  required: true,
                  message: "Please input your Your Country!",
                },
              ]}
            >
              <Input
                value={country}
                onChange={(text) => setcountry(text.target.value)}
                placeholder={t("Four")}
              />
            </Form.Item>
            <Form.Item
              name="Subject"
              label={t("Subject")}
              rules={[
                {
                  required: true,
                  message: "Please input your Subject !",
                },
              ]}
            >
              <Input
                value={subject}
                onChange={(text) => setsubject(text.target.value)}
                placeholder={t("Five")}
              />
            </Form.Item>
            <Form.Item
              name="instructions"
              label={t("Instructions")}
              rules={[
                {
                  required: true,
                  message: "Please input your Instructions !",
                },
              ]}
            >
              <TextArea
                rows={3}
                name="instructions"
                onChange={(text) => setinstructions(text.target.value)}
              />
            </Form.Item>
            {/* ----------------------------------------------- */}
            <Form.Item
              name="upload"
              label={t("Upload")}
              valuePropName="fileList"
              getValueFromEvent={normFile}
              rules={[
                {
                  required: true,
                  message: "Please input your file !",
                },
              ]}
            >
              <Upload
                name="logo"
                //value={files}
                fileList={files}
                action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                listType="picture"
                multiple
                onChange={({ fileList }) => setFiles(fileList)}
              >
                <Button icon={<UploadOutlined />}>{t("UploadClick")}</Button>
              </Upload>
            </Form.Item>
            {/* ---------------------------------------- */}
            <Form.Item
              name="Checkbox"
              label={t("CheckBox")}
              valuePropName="checked"
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.Checkbox !== currentValues.Checkbox
              }
              rules={[
                {
                  required: true,
                  message:
                    "Please Check the privacy policy page after clicking CheckBox ",
                },
              ]}
            >
              <Checkbox>
                <span>
                  I accept<span> </span>
                  <Link
                    style={{
                      color: "blue",
                    }}
                    to="/PrivacyPolicy"
                  >
                    The privacy policy
                  </Link>
                  <span> </span> for the use of this website.
                </span>
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" onClick={Post_Api}>
                {t("Submit")}
              </Button>
              <span>
                <span> </span>
              </span>
              <Button type="primary" danger onClick={handleReset}>
                {t("Reset")}
              </Button>
            </Form.Item>
          </Form>
        </div>
        {/* //todo End-Form */}
        {/* ------------------------------------------------------------------ */}
        <div className="flex-item-ContactUs-2">
          <div className="flex-item-ContactUs-list">
            <img src={img} alt=" " className="Logo-form"></img>
          </div>
          <div className="flex-item-ContactUs-list">25 Helen House</div>
          <div className="flex-item-ContactUs-list">Sorbonne Close</div>
          <div className="flex-item-ContactUs-list">Stockton-On-Tees</div>
          <div className="flex-item-ContactUs-list">TS17 6DF</div>
          <div className="flex-item-ContactUs-list">United Kingdom</div>
          <div className="flex-item-ContactUs-list">
            {/* <CallIcon /> */}
            <img src={phone} alt="email" width={35} height={35} />
            +44 7751655145
          </div>

          {/* <EmailIcon /> */}
          <div className="flex-item-ContactUs-list">
            <img src={emails} alt="email" width={35} height={35} />
            contact@kts4u.co.uk
          </div>

          <div className="flex-item-ContactUs-list">
            Company Number: 15568153
          </div>
        </div>
        <Button
          type="primary"
          className="scroll-to-top-button"
          onClick={scrollToTop} // Add onClick handler for scrolling to top
        >
          <ArrowUpOutlined />
        </Button>
      </div>
      <Footer />
    </div>
  );
};

export default Book_now;
