import "./Header.css";

import img from "./images/hexxader2.jpg";
const Header = () => {
  return (
    <div>
      <img src={img} alt=" " className="img-header" />
    </div>
  );
};

export default Header;
