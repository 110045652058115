import { Link } from "react-router-dom";
import "../Pages/Home.css";
import { ArrowUpOutlined } from "@ant-design/icons";
import { animateScroll as scroll } from "react-scroll";
import Footer from "./Footer";
import { Button } from "antd";
const PrivacyPolicy = () => {
  //todo --------------------------------------------
  const scrollToTop = () => {
    scroll.scrollToTop(); // Scroll to the top of the page
  };
  //todo --------------------------------------------
  return (
    <div>
      <div className="Text-Top">
        <h1
          style={{
            fontSize: 30,
          }}
          className="Title"
        >
          Privacy Policy
        </h1>
        <br></br>
        <br></br>
        <br></br>

        <div className="Text-Home-1">
          <p className="Text_p">
            Kurdish Translation Services 4U Ltd (us, we, or our), operates
            <br />
            <Link
              to="/"
              style={{
                color: "blue",
              }}
            >
              <b>www.kurdishtranslationservices4u.co.uk</b>
            </Link>
            <span></span> This page informs you of our policies. By using our
            website you agree to be bound by these policies. Before using the
            Site, it is important to read this statement carefully.
          </p>
        </div>
        <br></br>
        <br></br>
        <br></br>

        <div className="Text-Home-1">
          <h1>1- Links To Other Websites</h1>
          <p className="Text_p">
            This website contains links to other websites such as Facebook,
            LinkedIn and others that are not owned or controlled by us. Please
            be aware that Kurdish Translation Services 4U Ltd is not responsible
            or liable for the privacy policies and practices of such other
            Sites. This privacy policy applies only to information collected by
            this website.
          </p>
        </div>
        <br></br>
        <br></br>
        <br></br>

        {/* -------------------------------------- */}
        <div className="Text-Home-1">
          <h1>2- Changes</h1>
          <p className="Text_p">
            We may update this Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on the Site.
            Please check our website frequently to see any updates to our
            policy.
          </p>
        </div>
        <br></br>
        <br></br>
        <br></br>

        {/* -------------------------------------- */}
        <div className="Text-Home-1">
          <h1>3- Information Collection And Use</h1>
          <p className="Text_p">
            We use your personal information to ensure that we provide the very
            best possible service. We may request specific personally
            identifying information from you while you use our Site so that we
            can get in touch with or identify you. Your name is one example of
            personally identifiable information, but it is not the only one
            (personal information).
          </p>
        </div>
        <br></br>
        <br></br>
        <br></br>

        {/* -------------------------------------- */}
        <div className="Text-Home-1">
          <h1>4- Security</h1>
          <p className="Text_p">
            Ensuring the accuracy and currency of the personal information we
            collect about you is important. If your personal information has
            changed during the duration of your connection with us, kindly get
            in contact. We care about the security of your personal information.
            We have put in place sufficient physical, technological, and
            administrative systems to safeguard and secure the information we
            collect in order to prevent unauthorized access or disclosure.
            <br />
            <br></br> We will email your work as Word or PDF document as it is
            completed. You don’t receive a physical copy from us. This is
            because of security concerns, which will cause a delay in the
            process and increase the chance of mail loss.
          </p>
        </div>
        <br></br>
        <br></br>
        <br></br>

        {/* -------------------------------------- */}
        <div className="Text-Home-1">
          <h1>5- Governing Law</h1>
          <p className="Text_p">
            Use of this website and any dispute arising out of such use will be
            governed by the laws of the United Kingdom.
            <br />
            <br />
            If you have any questions about these privacy policies, please
            contact us.
          </p>
        </div>
        <br></br>
        <br></br>
        <br></br>

        {/* -------------------------------------- */}
        <Button
          type="primary"
          className="scroll-to-top-button"
          onClick={scrollToTop} // Add onClick handler for scrolling to top
        >
          <ArrowUpOutlined />
        </Button>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
