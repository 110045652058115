/* eslint-disable jsx-a11y/img-redundant-alt */

import kurdish from "../Navbar/Image/flag.png";
import english from "../Navbar/Image/united-kingdom (2).png";
import "./Languse.css";
import { FormControl, MenuItem, InputLabel, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { useState, useEffect } from "react";
import Cookies from "js-cookie";
const Language = () => {
  //Todo--------------
  const [language, setLanguage] = useState("");
  const { t } = useTranslation();
  const onChangeLang = (e) => {
    const selectedLang = e.target.value;
    setLanguage(selectedLang);
    i18n.changeLanguage(selectedLang);
    Cookies.set("language", selectedLang); // Save selected language to a cookie
    // localStorage.removeItem("i18nextLng");
    // window.location.reload();
  };

  useEffect(() => {
    const savedLang = Cookies.get("language");
    if (savedLang) {
      setLanguage(savedLang);
      i18n.changeLanguage(savedLang);
    }
  }, []);
  //Todo--------------
  return (
    <div className="language-container">
      <FormControl className="language-form-control">
        <InputLabel
          id="demo-simple-select-label"
          className="language-input-label"
        >
          <div className="language-input-label">
            <span className="label-text">{t("Lang")}</span>
          </div>
        </InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language || ""}
          onChange={onChangeLang}
        >
          <MenuItem value="en">
            <div className="language-select">
              <img src={english} alt="English Flag" />
              <span className="label-text">{t("English")}</span>
            </div>
          </MenuItem>
          <MenuItem value="ku">
            <div className="language-select">
              <img src={kurdish} alt="Kurdish Flag" />
              <span className="label-text">{t("Kurdish")}</span>
            </div>
          </MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default Language;
