import "./Home.css";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import img from "./images/automated-transcription-by-sonix-4e4134c959ef99b51f73867ad7295712.jpeg";
import Footer from "../Components/Footer";
import { useTranslation } from "react-i18next";

const Transcriptions = () => {
  const { t, i18n } = useTranslation();

  const direction = ["ku"].includes(i18n.language) ? "rtl" : "ltr";
  const textAlign = ["ku"].includes(i18n.language) ? "right" : "left";
  return (
    <div>
      <div className="Text-Top" style={{ direction, textAlign }}>
        <h1 className="Title">{t("TranscriptionSSS")}</h1>
        <div className="Text-Home-1">
          <p className="Text_p">{t("Transcription")}</p>
        </div>
      </div>

      <div className="flex-container">
        <div className="flex-item-1">
          <img
            src={img}
            alt="Automated transcription by Sonix"
            height={200}
            width={200}
          />
        </div>
        <div className="flex-item-2">
          <Button variant="contained" size="small">
            <Link to="/Book_now" className="link_Button">
              Get a free quote
            </Link>
          </Button>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Transcriptions;
