import "./Home.css";

import Footer from "../Components/Footer";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Interpretings from "./images/interpreterssss.png";
const Interpreting = () => {
  const { t, i18n } = useTranslation();

  const direction = ["ku"].includes(i18n.language) ? "rtl" : "ltr";
  const textAlign = ["ku"].includes(i18n.language) ? "right" : "left";
  return (
    <div>
      <div className="Text-Top" style={{ direction, textAlign }}>
        <h1 className="Title">{t("RemoteInterpretingS")}</h1>
        <div className="Text-Home-1">
          <p className="Text_p">{t("Interception")}</p>
        </div>
      </div>

      <div className="flex-container">
        <div className="flex-item-1">
          <img src={Interpretings} alt="img " height={200} width={200} />
        </div>
        <div className="flex-item-2">
          <Button variant="contained" size="small">
            <Link to="/Book_now" className="link_Button" href="index.html">
              Get a free quote
            </Link>
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Interpreting;
