import "./Home.css";

import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import img from "./images/unnamed.png";
import Footer from "../Components/Footer";
import { useTranslation } from "react-i18next";

const Translations = () => {
  const { t, i18n } = useTranslation();

  const direction = ["ku"].includes(i18n.language) ? "rtl" : "ltr";
  const textAlign = ["ku"].includes(i18n.language) ? "right" : "left";
  return (
    <div>
      <div className="Text-Top" style={{ textAlign, direction }}>
        <h1 className="Title">{t("TranslationSSS")}</h1>
        <div className="Text-Home-1">
          <p className="Text_p">{t("Translation")}</p>
        </div>
        <div className="Text-Home-2">
          <p className="Text_p"></p>
        </div>
      </div>
      {/* <div>
        <button>Get a free quote ≥ </button>
      </div> */}
      {/* --------------------------- */}
      <div className="flex-container">
        <div className="flex-item-1">
          <img src={img} alt="img " height={200} width={200} />
        </div>
        <div className="flex-item-2">
          <Button variant="contained" size="small">
            <Link to="/Book_now" className="link_Button" href="index.html">
              Get a free quote
            </Link>
          </Button>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Translations;
