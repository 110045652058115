/* eslint-disable react/jsx-pascal-case */
import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./FrontEnd/Pages/Home";
import AboutUs from "./FrontEnd/Pages/AboutUs";
import Navbar from "./FrontEnd/Navbar/Navbar";

import Translations from "./FrontEnd/Pages/Translations";
import Transcriptions from "./FrontEnd/Pages/Transcriptions";
import PrivacyPolicy from "./FrontEnd/Components/PrivacyPolicy";
import Book_now from "./FrontEnd/Pages/BookNow";
import Contact_us from "./FrontEnd/Pages/ContactUs";
import Interpreting from "./FrontEnd/Pages/Interpreting";
import i18n from "./i18n";

import { useEffect } from "react";
function App() {
  useEffect(() => {
    i18n.changeLanguage("en");
  }, []);
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="AboutUs" element={<AboutUs />} />
        <Route path="Translations" element={<Translations />} />
        <Route path="Transcriptions" element={<Transcriptions />} />
        <Route path="Interpreting" element={<Interpreting />} />
        <Route path="PrivacyPolicy" element={<PrivacyPolicy />} />
        <Route path="Book_now" element={<Book_now />} />
        <Route path="ContactUs" element={<Contact_us />} />
      </Routes>
    </div>
  );
}

export default App;
