import React, { useState } from "react";
import {
  AppBar,
  Menu,
  MenuItem,
  Tabs,
  Tab,
  Toolbar,
  useMediaQuery,
  useTheme,
  ListItemIcon,
} from "@mui/material";
import logo from "../Pages/images/Kurdish Translation Services.png";
// import Kurdstan from "./Image/flag.png";
// import UK from "./Image/united-kingdom (2).png";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import WorkIcon from "@mui/icons-material/Work";
import PhoneIcon from "@mui/icons-material/Phone";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import InfoIcon from "@mui/icons-material/Info";
import TranslateIcon from "@mui/icons-material/Translate";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import GTranslateIcon from "@mui/icons-material/GTranslate";
// import LanguageIcon from "@mui/icons-material/Language";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DrawerComp from "./Drawer";
// import i18n from "../../i18n";
import { useTranslation } from "react-i18next";
// import Cookies from "js-cookie";
import Language from "../Pages/Language";

const Navbar = () => {
  // Initialize value with a default value of 0 (first tab)
  const [value, setValue] = useState(0);
  // const [anchorEl, setAnchorEl] = useState(null); // State for language menu anchor
  const [servicesAnchorEl, setServicesAnchorEl] = useState(null); // State for services menu anchor
  const theme = useTheme();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));

  // eslint-disable-next-line no-unused-vars
  // const [language, setLanguage] = useState("");
  const { t } = useTranslation();

  // const onChangeLang = (selectedLang) => {
  //   setLanguage(selectedLang);
  //   i18n.changeLanguage(selectedLang);
  //   Cookies.set("language", selectedLang);
  //   localStorage.removeItem("i18nextLng");
  // };

  // useEffect(() => {
  //   const savedLang = Cookies.get("language");
  //   if (savedLang) {
  //     setLanguage(savedLang);
  //     i18n.changeLanguage(savedLang);
  //   }
  // }, []);

  // Handle language menu click
  // const handleLanguageClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };

  // // Handle menu close
  // const handleLanguageClose = () => {
  //   setAnchorEl(null);
  // };

  // Handle services menu click
  const handleServicesClick = (event) => {
    setServicesAnchorEl(event.currentTarget); // Open the services menu
  };

  // Handle services menu close
  const handleServicesClose = () => {
    setServicesAnchorEl(null); // Close the services menu
  };

  // Helper component for displaying icons with labels
  const TabLabelWithIcon = ({ label }) => (
    <div style={{ display: "flex", alignItems: "center" }}>
      {label} <ArrowDropDownIcon />
    </div>
  );

  return (
    <React.Fragment>
      <AppBar
        sx={{
          backgroundColor: "aliceblue",
          color: "black",
          fontWeight: "bold",
          fontSize: "16px",
        }}
      >
        <Toolbar>
          {isMatch ? (
            <>
              <div className="class-logo">
                <img src={logo} alt="Logo" height={35} width={270} />
              </div>
              <DrawerComp />
            </>
          ) : (
            <>
              <div className="class-logo">
                <img src={logo} alt="Logo" height={35} width={270} />
              </div>
              <Tabs
                sx={{ marginLeft: "auto" }}
                indicatorColor="secondary"
                textColor="inherit"
                value={value}
                onChange={(e, value) => setValue(value)}
              >
                <Tab
                  component={Link}
                  to="/"
                  label={t("Home")}
                  icon={<HomeIcon />}
                  iconPosition="start"
                  sx={{ fontWeight: "bold" }}
                />
                <Tab
                  label={<TabLabelWithIcon label={t("Server")} />}
                  icon={<WorkIcon />}
                  iconPosition="start"
                  onClick={handleServicesClick}
                  sx={{ fontWeight: "bold" }}
                />
                <Tab
                  component={Link}
                  to="/ContactUs"
                  label={t("Contact")}
                  icon={<PhoneIcon />}
                  iconPosition="start"
                  sx={{ fontWeight: "bold" }}
                />
                <Tab
                  component={Link}
                  to="/Book_now"
                  label={t("BookNow")}
                  icon={<EventAvailableIcon />}
                  iconPosition="start"
                  sx={{ fontWeight: "bold" }}
                />
                <Tab
                  component={Link}
                  to="/AboutUs"
                  label={t("About")}
                  icon={<InfoIcon />}
                  iconPosition="start"
                  sx={{ fontWeight: "bold" }}
                />
                {/* <Tab
                  label={<TabLabelWithIcon label={t("Lang")} />}
                  icon={<LanguageIcon />}
                  iconPosition="start"
                  onClick={handleLanguageClick}
                  sx={{ fontWeight: "bold" }}
                /> */}
                <Language />
              </Tabs>

              {/* Language Selection Menu */}
              {/* <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleLanguageClose}
              >
                <MenuItem
                  onClick={() => {
                    onChangeLang("en");
                    handleLanguageClose();
                  }}
                >
                  <ListItemIcon>
                    <img src={UK} alt="UK Flag" height={30} width={30} />
                  </ListItemIcon>
                  {t("English")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onChangeLang("ku");
                    handleLanguageClose();
                  }}
                >
                  <ListItemIcon>
                    <img
                      src={Kurdstan}
                      alt="Kurdistan Flag"
                      height={25}
                      width={25}
                    />
                  </ListItemIcon>
                  {t("Kurdish")}
                </MenuItem>
              </Menu> */}

              {/* Services Selection Menu */}
              <Menu
                anchorEl={servicesAnchorEl}
                open={Boolean(servicesAnchorEl)}
                onClose={handleServicesClose}
              >
                <MenuItem
                  component={Link}
                  to="/Translations"
                  onClick={handleServicesClose}
                >
                  <ListItemIcon>
                    <TranslateIcon />
                  </ListItemIcon>
                  {t("Translations")}
                </MenuItem>

                <MenuItem
                  component={Link}
                  to="/Transcriptions"
                  onClick={handleServicesClose}
                >
                  <ListItemIcon>
                    <SubtitlesIcon />
                  </ListItemIcon>
                  {t("Transcriptions")}
                </MenuItem>
                <MenuItem
                  component={Link}
                  to="/Interpreting"
                  onClick={handleServicesClose}
                >
                  <ListItemIcon>
                    <GTranslateIcon />
                  </ListItemIcon>
                  {t("Interpreting")}
                </MenuItem>
              </Menu>
            </>
          )}
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
};

export default Navbar;
