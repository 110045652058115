/* eslint-disable no-template-curly-in-string */
import { Form, Button, Input, message } from "antd";
import img from "./images/Kurdish Translation Services.png";
import { ArrowUpOutlined } from "@ant-design/icons";
import { animateScroll as scroll } from "react-scroll";
import emails from "./images/email.png";
import phone from "./images/telephone-call.png";
import "./Home.css";
import { useState } from "react";
import Footer from "../Components/Footer";
import { useTranslation } from "react-i18next";
import MapLearn from "../Map/MapLearn";
const Contact_us = () => {
  const { t } = useTranslation();
  const [full_name, setFullname] = useState("");
  const [phone_number, setphone_number] = useState(0);
  const [email, setemail] = useState("");
  const [country, setcountry] = useState("");
  const [subject, setsubject] = useState("");
  const [question, setquestion] = useState("");

  const Post_Api = async () => {
    const URI = "https://api.kurdishtranslationservices4u.co.uk/api/contacts";
    try {
      let res = await fetch(URI, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          full_name,
          phone_number,
          email,
          country,
          subject,
          question,
        }),
      });

      if (!res.ok) {
        throw new Error(`HTTP error! Status: ${res.status}`);
      }

      // eslint-disable-next-line no-unused-vars
      let data = await res.json();
      // console.log("Response:", data);

      // Handle success message here
      message.success("Form submitted successfully");
    } catch (error) {
      // console.error("Error:", error);

      // Handle error message here
      message.error("Failed to submit form. Please try again later.");
    }
  };

  //todo ---------------------------------------------
  const scrollToTop = () => {
    scroll.scrollToTop(); // Scroll to the top of the page
  };
  //todo ---------------------------------------------
  const [form] = Form.useForm();
  const handleReset = () => {
    // Reset the form fields
    form.resetFields();
  };

  //   --------------------------------
  const onFinish = (values) => {
    // console.log("Success", values);
  };
  const onFinishFailed = (errorInfo) => {
    // console.log("Failed:", errorInfo);
  };
  const { TextArea } = Input;
  const validateMessages = {
    types: {
      email: "${label} is not a valid email!",
    },
  };
  const layout = {
    labelCol: {
      span: 4,
    },
    wrapperCol: {
      span: 16,
    },
  };
  //   -------------------------------------

  return (
    <div>
      <div className="flex-container-ContactUs">
        <div className="flex-item-ContactUs-1">
          <p id="paragraph_form">{t("PageContactTitle")}</p>
          <Form
            form={form}
            {...layout}
            style={{
              maxWidth: 800,
            }}
            validateMessages={validateMessages}
            name="frm"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              name="FullName"
              label={t("FullName")}
              rules={[
                {
                  required: true,
                  message: "Please input your Full Name ",
                },
              ]}
            >
              <Input
                value={full_name}
                onChange={(text) => {
                  setFullname(text.target.value);
                }}
                placeholder={t("One")}
              />
            </Form.Item>
            <Form.Item
              name="PhoneNumber"
              label={t("Phone")}
              rules={[
                {
                  required: true,
                  message: "Please input your Phone Number!",
                },
              ]}
            >
              <Input
                value={phone_number}
                onChange={(text) => setphone_number(text.target.value)}
                placeholder={t("Two")}
              />
            </Form.Item>
            <Form.Item
              name="Email"
              label={t("Email")}
              rules={[
                {
                  required: true,
                  message: "Please input your Email!",
                  type: "email",
                },
              ]}
            >
              <Input
                value={email}
                onChange={(text) => setemail(text.target.value)}
                placeholder={t("Three")}
              />
            </Form.Item>
            <Form.Item
              name="YourCountry"
              label={t("YourCountry")}
              rules={[
                {
                  required: true,
                  message: "Please input your Your Country!",
                },
              ]}
            >
              <Input
                value={country}
                onChange={(text) => setcountry(text.target.value)}
                placeholder={t("Four")}
              />
            </Form.Item>
            <Form.Item
              name="Subject"
              label={t("Subject")}
              rules={[
                {
                  required: true,
                  message: "Please input your Subject !",
                },
              ]}
            >
              <Input
                value={subject}
                onChange={(text) => setsubject(text.target.value)}
                placeholder={t("Five")}
              />
            </Form.Item>
            <Form.Item
              name="YourQuestion"
              label={t("YourQuestion")}
              // rules={[
              //   {
              //     required: true,
              //     message: "Please input your Your Question !",
              //   },
              // ]}
            >
              <TextArea
                name="question"
                onChange={(text) => setquestion(text.target.value)}
                rows={3}
              />
            </Form.Item>
            {/* ----------------------------------------------- */}

            <Form.Item>
              <Button type="primary" onClick={Post_Api}>
                {t("Submit")}
              </Button>
              <span>
                <span> </span>
              </span>
              <Button type="primary" danger onClick={handleReset}>
                {t("Reset")}
              </Button>
            </Form.Item>
          </Form>
        </div>
        {/* //todo End-Form */}
        {/* ------------------------------------------------------------------ */}
        <div className="flex-item-ContactUs-2">
          <div className="flex-item-ContactUs-list">
            <img src={img} alt=" " className="Logo-form"></img>
          </div>
          <div className="flex-item-ContactUs-list">25 Helen House</div>
          <div className="flex-item-ContactUs-list">Sorbonne Close</div>
          <div className="flex-item-ContactUs-list">Stockton-On-Tees</div>
          <div className="flex-item-ContactUs-list">TS17 6DF</div>
          <div className="flex-item-ContactUs-list">United Kingdom</div>
          <div className="flex-item-ContactUs-list">
            {/* <CallIcon /> */}
            <img src={phone} alt="email" width={35} height={35} />
            +44 7751655145
          </div>

          {/* <EmailIcon /> */}
          <div className="flex-item-ContactUs-list">
            <img src={emails} alt="email" width={35} height={35} />
            contact@kts4u.co.uk
          </div>

          <div className="flex-item-ContactUs-list">
            Company Number: 15568153
          </div>
        </div>
        <Button
          type="primary"
          className="scroll-to-top-button"
          onClick={scrollToTop} // Add onClick handler for scrolling to top
        >
          <ArrowUpOutlined />
        </Button>
      </div>
      <MapLearn />
      <Footer />
    </div>
  );
};

export default Contact_us;
