import { MapContainer, Marker, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "leaflet/dist/leaflet.css";
import { Icon, divIcon, point } from "leaflet";
import "./Style/map.css";
const MapLearn = () => {
  const markers = [{ id: 1, geocode: [54.5602466, -1.3070571] }];

  const customIcon = new Icon({
    iconUrl: require("./Image/marker-icon.png"),
    iconSize: [38, 38],
  });

  const createCustomClusterIcon = (cluster) => {
    return new divIcon({
      html: `<div class ="cluster-icon">${cluster.getChildCount()}</div>`,
      className: "custom-marker-cluster",
      iconSize: point(33, 33, true),
    });
  };
  // Function to handle marker click and redirect to Google Maps
  const handleMarkerClick = (lat, lng) => {
    const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
    window.open(googleMapsUrl, "_blank"); // Open in a new tab
  };

  return (
    <div>
      <MapContainer center={[54.5602466, -1.3070571]} zoom={13}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup
          chunkedLoading
          iconCreateFunction={createCustomClusterIcon}
        >
          {markers.map((marker) => (
            <Marker
              key={marker.id}
              position={marker.geocode}
              icon={customIcon}
              eventHandlers={{
                click: () =>
                  handleMarkerClick(marker.geocode[0], marker.geocode[1]),
              }}
            >
              {/* <Popup>{marker.popUp}</Popup> */}
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
};

export default MapLearn;
