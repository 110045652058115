import "./Home.css";

import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Header from "../Components/Header";
import { useTranslation } from "react-i18next";
import img from "./images/translate.png";
import ima2 from "./images/transcript (1).png";
import ima3 from "./images/interpreter.png";
import Footer from "../Components/Footer";

const Home = () => {
  const { t, i18n } = useTranslation();

  const direction = ["ku"].includes(i18n.language) ? "rtl" : "ltr";
  const textAlign = ["ku"].includes(i18n.language) ? "right" : "left";
  return (
    <div>
      <div style={{ direction, textAlign }}>
        <Header />
        {/* ----------------------- */}
        <div className="Text-Top">
          <div className="Text-Home-1">
            <p className="Text_p">{t("Home1")}</p>
          </div>
        </div>

        {/* //todo---------------------------------------------------------- */}
        {/* //todo---------------------------------------------------------- */}
        {/* //todo---------------------------------------------------------- */}
        <div className="grid-container">
          <div className="grid-cell">
            <ul className="item-UI">
              <li className="item-icon">
                <img src={img} alt=" " width={120} height={120} />
              </li>
              <li className="item-title">
                <Link to="/Translations" className="item-title-link">
                  {t("HomeTranslation")}
                </Link>
              </li>
              <li className="item-paragraph">{t("HomeTranslationTitle")}</li>
            </ul>
          </div>
          <div className="grid-cell">
            <ul className="item-UI">
              <li className="item-icon">
                <img src={ima3} alt=" " width={120} height={120} />
              </li>
              <li className="item-title">
                <Link to="/Interpreting" className="item-title-link">
                  {t("HomeInterpreting")}
                </Link>
              </li>
              <li className="item-paragraph">{t("HomeInterpretingTitle")}</li>
            </ul>
          </div>
          <div className="grid-cell">
            <ul className="item-UI">
              <li className="item-icon">
                <img src={ima2} alt=" " width={120} height={120} />
              </li>
              <li className="item-title">
                <Link to="/Transcriptions" className="item-title-link">
                  {t("HomeTranscriptions")}
                </Link>
              </li>
              <li className="item-paragraph">{t("HomeTranscriptionsTitle")}</li>
            </ul>
          </div>
        </div>

        {/* //todo ---------------------------------------------------------- */}
        {/* //todo ---------------------------------------------------------- */}
        {/* //todo ---------------------------------------------------------- */}

        {/* ------------------------------------- */}
        <div className="flex-container">
          <div className="flex-item-1">
            <b>{t("ContactTitle")}</b> <br />
            {t("ContactDeception")}
          </div>
          <div className="flex-item-2">
            <Button variant="contained" size="small">
              <Link to="/ContactUs" className="link_Button" href="index.html">
                {t("Contact")}
              </Link>
            </Button>
          </div>
        </div>
        <div className="flex-container">
          <div className="flex-item-1">
            <b>{t("BookNowTitle")}</b> <br></br>
            {t("BookNowDeception")}
          </div>
          <div className="flex-item-2">
            <Button variant="contained" size="small">
              <Link to="/Book_now" className="link_Button">
                {t("BookNowTop")}
              </Link>
            </Button>
          </div>
        </div>
        {i18n.language !== "ku" && (
          <div className="flex-container">
            <div className="flex-item-1">
              <b>Need to book a Remote Interpreter </b> <br />
              Click on the Book Button Now to get in touch
            </div>
            <div className="flex-item-2">
              <Button variant="contained" size="small">
                <Link to="/Book_now" className="link_Button" href="index.html">
                  Book Now
                </Link>
              </Button>
            </div>
          </div>
        )}
        {/* ------------------------------- */}
      </div>
      <Footer />
    </div>
  );
};

export default Home;
