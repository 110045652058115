import "./Footer.css";

import Facebook from "./images/facebook.png";
import linkedin from "./images/linkedin.png";
import what_is_up from "./images/whatsapp.png";
import Email from "./images/email.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="sb_footer section-padding">
        <div className="sb_footer-Link">
          <div className="sb_footer-links-div">
            <h1 className="Title">{t("connectwithus")}</h1>

            <p>
              <Link
                style={{
                  color: "blue",
                }}
                to="/ContactUs"
              >
                <strong>{t("Connect")}</strong>
              </Link>
            </p>

            <a
              href="https://api.whatsapp.com/send?phone=447751655145&text=KTS4U"
              target="_black"
            >
              <p>
                <img src={what_is_up} alt=" " width={30} height={30} />
                <strong>+44 7751655145</strong>
              </p>
            </a>
            <a href="mailto:contact@kts4u.co.uk" target="_black">
              <p>
                <img src={Email} alt=" " width={30} height={30} />
                <span className="footer_Email">contact@kts4u.co.uk</span>
              </p>
            </a>
            <div className="social-media">
              <p>
                <a
                  href="https://www.facebook.com/profile.php?id=61554958468333&mibextid=2JQ9oc"
                  target="_black"
                >
                  <img src={Facebook} alt=" " width={50} height={50} />
                </a>
              </p>
              <p>
                <a
                  href="http://linkedin.com/in/kurdish-translation-services-4u-1282b12a9"
                  target="_black"
                >
                  <img src={linkedin} alt=" " width={50} height={50} />
                </a>
              </p>
            </div>
          </div>
          <div className="sb_footer-links-div">
            <strong
              style={{
                fontSize: 20,
                fontWeight: "bold",
              }}
              className="Title"
            >
              Kurdish Translation Services 4U Ltd
            </strong>
          </div>
        </div>
        <hr></hr>
        <div className="sb_footer-below">
          <div className="sb-footer-copyright">
            <h4
              style={{
                color: "black",
              }}
            >
              {new Date().getFullYear()} Kurdish Translation Services 4U Ltd
            </h4>
          </div>

          <div>
            <Link
              style={{
                color: "blue",
              }}
              to="/PrivacyPolicy"
            >
              Privacy Policy
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
