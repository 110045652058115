import React, { useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import WorkIcon from "@mui/icons-material/Work";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import InfoIcon from "@mui/icons-material/Info";
import TranslateIcon from "@mui/icons-material/Translate";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import GTranslateIcon from "@mui/icons-material/GTranslate";
// import LanguageIcon from "@mui/icons-material/Language";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FacebookIcon from "@mui/icons-material/Facebook";
import MailOutlineIcon from "@mui/icons-material/MailOutline"; // Updated import
import WhatsAppIcon from "@mui/icons-material/WhatsApp"; // Updated WhatsAppIcon import
import LinkedInIcon from "@mui/icons-material/LinkedIn";
// import Kurdstan from "./Image/flag.png";
// import UK from "./Image/united-kingdom (2).png";
// import i18n from "../../i18n";
// import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import Language from "../Pages/Language";
const DrawerComp = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorElServices, setAnchorElServices] = useState(null);
  // const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  // eslint-disable-next-line no-unused-vars
  // const [language, setLanguage] = useState("");
  const { t } = useTranslation();

  // const onChangeLang = (selectedLang) => {
  //   setLanguage(selectedLang);
  //   i18n.changeLanguage(selectedLang);
  //   Cookies.set("language", selectedLang);
  //   localStorage.removeItem("i18nextLng");
  // };

  // useEffect(() => {
  //   const savedLang = Cookies.get("language");
  //   if (savedLang) {
  //     setLanguage(savedLang);
  //     i18n.changeLanguage(savedLang);
  //   }
  // }, []);

  const handleMenuItemClick = () => {
    setOpenDrawer(false);
  };

  const handleServicesClick = (event) => {
    setAnchorElServices(event.currentTarget);
  };

  const handleServicesClose = () => {
    setAnchorElServices(null);
  };

  // const handleLanguageClick = (event) => {
  //   setAnchorElLanguage(event.currentTarget);
  // };

  // const handleLanguageClose = () => {
  //   setAnchorElLanguage(null);
  //   setOpenDrawer(false);
  // };

  const isServicesOpen = Boolean(anchorElServices);

  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          sx: {
            width: 250,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
          },
        }}
      >
        <List>
          <ListItemButton component={Link} to="/" onClick={handleMenuItemClick}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>{t("Home")}</ListItemText>
          </ListItemButton>

          {/* Services option with dropdown menu */}
          <ListItemButton onClick={handleServicesClick}>
            <ListItemIcon>
              <WorkIcon />
            </ListItemIcon>
            <ListItemText>{t("Server")}</ListItemText>
            <ExpandMoreIcon />
          </ListItemButton>
          <Menu
            anchorEl={anchorElServices}
            open={Boolean(anchorElServices)}
            onClose={handleServicesClose}
          >
            <MenuItem
              component={Link}
              to="/Translations"
              onClick={handleServicesClose}
            >
              <ListItemIcon>
                <TranslateIcon />
              </ListItemIcon>

              {t("Translations")}
            </MenuItem>

            <MenuItem
              component={Link}
              to="/Transcriptions"
              onClick={handleServicesClose}
            >
              <ListItemIcon>
                <SubtitlesIcon />
              </ListItemIcon>
              {t("Transcriptions")}
            </MenuItem>
            <MenuItem
              component={Link}
              to="/Interpreting"
              onClick={handleServicesClose}
            >
              <ListItemIcon>
                <GTranslateIcon />
              </ListItemIcon>

              {t("Interpreting")}
            </MenuItem>
          </Menu>

          {!isServicesOpen && (
            <Box>
              <ListItemButton
                component={Link}
                to="/ContactUs"
                onClick={handleMenuItemClick}
              >
                <ListItemIcon>
                  <PhoneIcon />
                </ListItemIcon>
                <ListItemText>{t("Contact")}</ListItemText>
              </ListItemButton>
              <ListItemButton
                component={Link}
                to="/Book_now"
                onClick={handleMenuItemClick}
              >
                <ListItemIcon>
                  <EventAvailableIcon />
                </ListItemIcon>
                <ListItemText>{t("BookNow")}</ListItemText>
              </ListItemButton>
              <ListItemButton
                component={Link}
                to="/AboutUs"
                onClick={handleMenuItemClick}
              >
                <ListItemIcon>
                  <InfoIcon />
                </ListItemIcon>
                <ListItemText>{t("About")}</ListItemText>
              </ListItemButton>

              {/* Language option with dropdown menu */}
              {/* <ListItemButton onClick={handleLanguageClick}>
                <ListItemIcon>
                  <LanguageIcon />
                </ListItemIcon>
                <ListItemText>{t("Lang")}</ListItemText>
                <ExpandMoreIcon />
              </ListItemButton>
              <Menu
                value={language || ""}
                anchorEl={anchorElLanguage}
                open={Boolean(anchorElLanguage)}
                onClose={handleLanguageClose}
              >
                <MenuItem
                  onClick={() => {
                    onChangeLang("en");
                    handleLanguageClose();
                  }}
                >
                  <img src={UK} alt="Kurdistan Flag" height={25} width={25} />
                  {t("English")}
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onChangeLang("ku");
                    handleLanguageClose();
                  }}
                >
                  <img
                    src={Kurdstan}
                    alt="Kurdistan Flag"
                    height={25}
                    width={25}
                  />
                  {t("Kurdish")}
                </MenuItem>
              </Menu> */}
              <Language />
            </Box>
          )}
        </List>

        {/* Social Media Icons at the Bottom */}
        <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
          <IconButton
            onClick={() =>
              window.open(
                "https://www.facebook.com/profile.php?id=61554958468333&mibextid=2JQ9oc",
                "_blank"
              )
            }
            sx={{ color: "#3b5998" }}
          >
            <FacebookIcon />
          </IconButton>
          <IconButton
            onClick={() => window.open("mailto:contact@kts4u.co.uk", "_blank")}
            sx={{ color: "#00acee" }}
          >
            <MailOutlineIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open(
                "https://api.whatsapp.com/send?phone=447751655145&text=KTS4U",
                "_blank"
              )
            }
            sx={{ color: "#00712D" }}
          >
            <WhatsAppIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              window.open(
                "http://linkedin.com/in/kurdish-translation-services-4u-1282b12a9",
                "_blank"
              )
            }
            sx={{ color: "#0e76a8" }}
          >
            <LinkedInIcon />
          </IconButton>
        </Box>
      </Drawer>
      <IconButton
        sx={{ color: "black", marginLeft: "auto", fontWeight: "bold" }}
        onClick={() => setOpenDrawer(!openDrawer)}
      >
        <MenuIcon />
      </IconButton>
    </React.Fragment>
  );
};

export default DrawerComp;
