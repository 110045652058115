import Footer from "../Components/Footer";
import "./Home.css";

import { useTranslation } from "react-i18next";

const About_us = () => {
  const { t, i18n } = useTranslation();
  // // Check if translations are loaded
  // if (!i18n.isInitialized) {
  //   return <p>Loading...</p>;
  // }
  const direction = ["ku"].includes(i18n.language) ? "rtl" : "ltr";
  const textAlign = ["ku"].includes(i18n.language) ? "right" : "left";
  return (
    <div>
      <div className="Text-Top" style={{ direction, textAlign }}>
        <div className="Text-Home-1">
          <h1 className="Title">{t("AboutUs")}</h1>
          <p className="Text_p">{t("about")}</p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About_us;
